export default {
  abbGrey255: '#FFFFFF',
  abbGrey250: '#FAFAFA',
  abbGrey240: '#F0F0F0',

  abbGrey235: '#ebebeb',

  abbGrey230: '#E6E6E6',
  abbGrey220: '#DCDCDC',
  abbGrey210: '#D2D2D2',
  abbGrey200: '#C8C8C8',
  abbGrey180: '#B4B4B4',
  abbGrey170: '#AAAAAA',
  abbGrey160: '#A0A0A0',
  abbGrey140: '#8C8C8C',
  abbGrey110: '#6E6E6E',
  abbGrey90: '#5A5A5A',
  abbGrey70: '#464646',
  abbGrey60: '#3c3c3c',
  abbGrey50: '#323232',
  abbGrey38: '#262626',
  abbGrey20: '#141414',
  abbGrey0: '#000000',

  abbBlue1: '#DDEDFD',
  abbBlue2: '#ABD4FD',
  abbBlue3: '#73B7FF',
  abbBlue4: '#2E92FA',
  abbBlue5: '#0C74DA',
  abbBlue6: '#004C97',

  abbPurple1: '#BAACDD',
  abbPurple2: '#A288CE',
  abbPurple3: '#8664BF',
  abbPurple4: '#6C46AA',
  abbPurple5: '#553786',
  abbPurple6: '#462D6E',
  abbPink1: '#DAA5CE',
  abbPink2: '#C675B3',
  abbPink3: '#B7519F',
  abbPink4: '#973E82',
  abbPink5: '#732F63',
  abbPink6: '#5B254E',
  abbPeach1: '#F3C8B8',
  abbPeach2: '#ECA88F',
  abbPeach3: '#DE8262',
  abbPeach4: '#CB6745',
  abbPeach5: '#A65033',
  abbPeach6: '#833B22',
  abbGold1: '#F3E7B5',
  abbGold2: '#F1DD8E',
  abbGold3: '#E1C96C',
  abbGold4: '#CBAF49',
  abbGold5: '#9E842F',
  abbGold6: '#7A641B',
  abbGreen1: '#C0ECD2',
  abbGreen2: '#92DEB1',
  abbGreen3: '#5CBE85',
  abbGreen4: '#329A5D',
  abbGreen5: '#007A33',
  abbGreen6: '#005C27',
  abbTurquoise1: '#B9EEF0',
  abbTurquoise2: '#90E4E6',
  abbTurquoise3: '#58D7DA',
  abbTurquoise4: '#28B1B5',
  abbTurquoise5: '#1F888B',
  abbTurquoise6: '#196D6F',
  abbAsphalt1: '#D7E4E9',
  abbAsphalt2: '#BCD0D4',
  abbAsphalt3: '#98B6BC',
  abbAsphalt4: '#80A0A6',
  abbAsphalt5: '#628288',
  abbAsphalt6: '#4B656A',

  abbAlarmYellow: '#FFD800',
  abbAlarmOrange: '#FF7300',
  abbAlarmRed: '#F03040',
  abbAlarmMagenta: '#CB2BD5',
  abbAlarmCyan: '#14F0F0',
  abbAlarmBlue: '#0052FF',

  accentColor: '#ff000f',
};
