import React from 'react';
import useStyles from './LandingPage.styles';
import Login from '../login/Login';

export default function LandingPage() {
  const classes = useStyles();

  return (
    <div className={classes.landingPage}>
      <Login />
    </div>
  );
}
