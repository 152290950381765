const keycloakLogin = (realm: string) => {
  // @ts-ignore
  const keycloak = Keycloak({
    url:
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_BASE_URL}/auth`
        : `${window.location.origin}/auth`,
    realm,
    clientId: 'insight-portal', // TODO: Create new Keycloak client and change this
  });

  keycloak
    .init({ onLoad: 'login-required', checkLoginIframe: false })
    .then((authenticated: boolean) => {
      if (authenticated) {
        window.location.href = process.env.NODE_ENV === 'development'
          ? `http://localhost:4003/${realm}`
          : `${window.location.origin}/${realm}`;
      }
    })
    .finally(() => {
      localStorage.removeItem('abb/portal-login/realm');
    });
};

export default keycloakLogin;
