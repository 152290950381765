import { createUseStyles } from 'react-jss';
import colors from '../../styles/colors';

export default createUseStyles({
  aside: {
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  '@media (min-width: 1024px)': {
    aside: {
      width: 400,
    },
  },
  header: {
    color: colors.abbGrey235,
    fontSize: '2rem',
    fontWeight: 500,
    marginBottom: '4rem',
  },
  logo: {
    marginLeft: '-18px',
  },
  bottom: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: colors.abbGrey235,
    fontWeight: 500,
    fontSize: '1rem',
    marginBottom: '0.5rem',
  },
  input: {
    color: colors.abbGrey235,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.33)',
    height: '3rem',
    marginBottom: '1.5rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    boxSizing: 'border-box',
    fontSize: '1rem',
  },
  button: {
    height: '3rem',
    background: colors.abbBlue5,
    color: colors.abbGrey235,
    fontWeight: 500,
    fontSize: '0.9rem',
  },
});
