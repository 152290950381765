import React from 'react';
import ReactDOM from 'react-dom';
import './styles/abb.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import keycloakLogin from './features/keycloakLogin/keycloakLogin';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// eslint-disable-next-line no-restricted-globals
if (location.hash) {
  keycloakLogin(localStorage.getItem('abb/portal-login/realm') || '');
}
