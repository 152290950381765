import { createUseStyles } from 'react-jss';
import background from './background.jpg';

export default createUseStyles({
  landingPage: {
    backgroundImage: `url(${background})`,
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
  },

});
