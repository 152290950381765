import React, { ChangeEvent, useState } from 'react';
import useStyles from './Login.styles';
import { ReactComponent as Logo } from './logo.svg';
import keycloakLogin from '../keycloakLogin/keycloakLogin';

export default function Login() {
  const classes = useStyles();
  const [customer, setCustomer] = useState('');
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>();

  const submit = () => {
    localStorage.setItem('abb/portal-login/realm', customer);

    keycloakLogin(customer);
  };

  const changeCustomer = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer(event.target.value);
  };

  if (inputRef) inputRef.focus();
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') submit();
  };

  return (
    <div className={classes.aside}>
      <a href="/">
        <Logo className={classes.logo} />
      </a>

      <div className={classes.bottom}>
        <h2 className={classes.header}>Operational Insight</h2>

        <label htmlFor="customer-name">
          <div className={classes.label}>Enter company name</div>
          <input id="customer-name" className={classes.input} onChange={changeCustomer} ref={setInputRef}
                 onKeyPress={onKeyPress} />

        </label>
        <button type="submit" className={classes.button} onClick={submit}>Sign in</button>
      </div>
    </div>
  );
}
